<template>
  <q-padder vertical="sm" class="content">
    <q-padder v-if="useTitle" horizontal="sm" bottom="sm">
      <q-text
        variant="ui-small"
        style="color: #707070"
        v-html="needHelpLabel"
        >{{ needHelpLabel }}</q-text
      >
    </q-padder>
    <q-grid :column="customerService.length">
      <div
        v-for="(item, index) in customerService"
        :key="index"
        :title="item.title"
        style="cursor: pointer"
        @click="onCustomerServiceClick(item.id)"
        @keypress="onCustomerServiceClick(item.id)"
      >
        <Column :style="item.style" justify="center" align-items="center">
          <img
            :alt="`icon-${item.title}`"
            :title="item.title"
            :src="item.img"
            style="object-fit: contain"
            class="logout-icon"
          />
          <q-padder top="xs">
            <q-text center ink="midnight" variant="ui-tiny">{{
              item.title
            }}</q-text>
            <q-text center ink="primary" variant="ui-tiny">{{
              item.desc
            }}</q-text>
          </q-padder>
        </Column>
      </div>
    </q-grid>
  </q-padder>
</template>
<script>
import {
  Padder as QPadder,
  Text as QText,
  Grid as QGrid,
  Column,
} from '@qoala/ui';
const whastappimage = 'https://assets.qoala.app/icons/whatsapp_user@2x.png';
const bubblecomments =
  'https://assets.qoala.app/icons/speech-bubbles-comment-option.png';
const email = 'https://assets.qoala.app/icons/email.png';
const phone = 'https://assets.qoala.app/icons/phone-receiver.png';
const zalo = 'https://assets.qoala.app/icons/zalo.png';
export default {
  components: {
    QPadder,
    QText,
    QGrid,
    Column,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    useTitle: {
      type: Boolean,
      default: true,
    },
    trackingEvent: {
      type: String,
      default: '',
    },
    pageType: {
      type: String,
      default: 'policy', // policy || claim
    },
  },
  data() {
    return {
      customerService: [
        {
          id: 1,
          name: 'Whatsapp',
          title: this.content.QoalaOnlineAccount.whatsAppLabel,
          desc: this.content.QoalaOnlineAccount.whatsAppDesc,
          style: 'border-right: 0.5px solid #cae4ee;padding:3px 3px',
          img: whastappimage,
        },
        {
          id: 2,
          name: 'Chat',
          title: this.content.QoalaOnlineAccount.chatLabel,
          desc: this.content.QoalaOnlineAccount.chatDesc,
          style: 'border-right: 0.5px solid #cae4ee;padding:3px 3px',
          img: bubblecomments,
        },
        {
          id: 3,
          name: 'Email',
          title: this.content.QoalaOnlineAccount.emailLabel,
          desc: this.content.QoalaOnlineAccount.emailDesc,
          style: 'border-right: 0.5px solid #cae4ee;padding:3px 3px',
          img: email,
        },
        {
          id: 4,
          name: 'Phone',
          title: this.content.QoalaOnlineAccount.callLabel,
          desc: this.content.QoalaOnlineAccount.callDesc,
          style: 'padding:3px 3px',
          img: phone,
        },
      ],
    };
  },
  computed: {
    userInformation() {
      return this.$store.getters['user/getUserData'];
    },
    getRouteName() {
      const str = this.$route.path.split('/')[2];
      return str || 'home';
    },
    isWorkingHour() {
      const start = this.$moment('09:00', 'HH:mm');
      const end = this.$moment('19:00', 'HH:mm');
      if (this.$moment().isBetween(start, end)) return true;
      else return false;
    },
    countryCode() {
      return this.$store.getters['core/getCountryCode'];
    },
    needHelpLabel() {
      if (this.countryCode === 'TH')
        return this.content.QoalaOnlineAccount.needHelpLabelTH;
      return this.content.QoalaOnlineAccount.needHelpLabel;
    },
  },
  created() {
    this.handleCountryPartners(this.countryCode);
  },
  methods: {
    disableCsSection() {
      const now = this.$moment().format('YYYY-MM-DDTHH:mm');
      const startDate = this.$moment().format('2020-12-31T19:00');
      const endDate = this.$moment().format('2021-01-01T07:00');
      if (this.$moment(now).isBetween(startDate, endDate)) {
        const message = {
          title: 'Halo,',
          message: `Kami mohon maaf untuk malam ini tanggal 31/12/2020 jam 19.00, Qoala tidak beroperasi dikarenakan alasan operasional. Jika kamu memiliki pertanyaan, silahkan menghubungi kami kembali besok tanggal 01/01/2021 pada jam 07.00. Kami akan dengan senang hati membantu menjawab pertanyaanmu.
          \n Selamat Tahun Baru 2021!"`,
        };
        this.$store.dispatch('general/setModalMessage', true);
        this.$store.dispatch('general/setMessage', message);
        return true;
      } else {
        return false;
      }
    },
    runWhatApp() {
      if (this.disableCsSection()) return;
      this.$analytics.pushEventClevertap('Whatsapp CS', {
        page: this.getRouteName,
      });
      const csQoala =
        this.pageType === 'policy' ? '6282210333220' : '6281119199341';
      this.$helper.openNewTab(
        `https://api.whatsapp.com/send?phone=${csQoala}`,
        '_blank'
      );
    },
    onCustomerServiceClick(id) {
      let contact;
      switch (id) {
        case 1:
          contact = 'Whatsapp';
          this.runWhatApp();
          break;
        case 2:
          contact = 'Chat';
          this.runChat();
          break;
        case 3:
          contact = 'Email';
          this.runEmail();
          break;
        case 4:
          contact = 'Call';
          this.runCall();
          break;
        default:
          break;
      }
      if (!this.$helper.isEmpty(this.trackingEvent)) {
        this.$analytics.pushEvent({
          event: `${this.trackingEvent}`,
          eventCategory:
            this.trackingEvent === 'Login-Page' ? 'Login' : this.trackingEvent,
          eventAction:
            this.trackingEvent === 'Login-Page'
              ? this.trackingEvent
              : 'CS-Help',
          eventLabel: `${this.trackingEvent}-Click-CS-${contact}`,
        });
      }
    },
    runChat() {
      this.$analytics.pushEventClevertap('Chat CS', {
        page: this.getRouteName,
      });

      if (this.disableCsSection()) return;
      let localeFreshChat = 'en';
      switch (this.countryCode) {
        case 'ID':
          localeFreshChat = 'id';
          break;
        case 'MY':
          localeFreshChat = 'my';
          break;
        case 'VN':
          localeFreshChat = 'vn';
          break;
        case 'TH':
          localeFreshChat = 'th';
          break;
        default:
          break;
      }

      if (localeFreshChat === 'vn') {
        this.$helper.openNewTab(
          `https://zalo.me/526804602236846658`,
          '_blank'
        );
        return;
      }

      const pointer = this;
      if (window.isApple) {
        window.setupWebViewJavascriptBridge((bridge) => {
          bridge.callHandler(
            'appleInitFreshchat',
            {
              externalId: pointer.userInformation.email,
              restoreId: pointer.userInformation.chattingId,
            },
            () => {
              bridge.callHandler('appleFreshchat', {}, () => {
                console.log('JS appleFreshchat response');
              });
            }
          );
        });
      } else {
        this.$nuxt.$loading.start();
        if (window.fcWidget.isInitialized()) {
          window.fcWidget.open();
        } else {
          if (!this.userInformation) {
            window.fcWidget.init({
              token: process.env.FRESHCAT_TOKEN,
              host: 'https://wchat.freshchat.com',
              locale: localeFreshChat,
              open: true,
              config: {
                headerProperty: {
                  hideChatButton: true,
                },
                hideFAQ: true,
              },
            });
          } else window.initChat(this.userInformation);
          window.fcWidget.on('widget:loaded', () => {
            window.fcWidget.open();
          });
        }
        document.getElementById('fc_frame').style.display = 'block';
      }
      return true;
    },
    runEmail() {
      this.$analytics.pushEventClevertap('Email CS', {
        page: this.getRouteName,
      });
      let qoalaEmail =
        this.pageType === 'policy' ? 'cs@qoala.id' : 'claim@qoala.id';
      if (this.countryCode === 'VN') {
        qoalaEmail = 'cs@qoala.vn';
      } else if (this.countryCode === 'TH') {
        qoalaEmail = 'cs@qoala.app';
      } else if (this.countryCode === 'MY') {
        qoalaEmail = 'cs@qoala.my';
      }
      window.location.href = `mailto:${qoalaEmail}?subject=Halo%20Qoala`;
    },
    runCall() {
      this.$analytics.pushEventClevertap('Call CS', {
        page: this.getRouteName,
      });
      if (this.$moment().isoWeekday() < 6 && this.isWorkingHour) {
        location.href = 'tel:+622150645035';
      } else {
        const message = {
          title: this.$t('sorry'),
          message: this.$t('callCsPopUpDesc'),
        };
        this.$store.dispatch('general/setModalMessage', true);
        this.$store.dispatch('general/setMessage', message);
      }
    },
    handleCountryPartners(country) {
      const contactSupport = {
        Whatsapp: 'Whatsapp',
        Chat: 'Chat',
        Email: 'Email',
        Phone: 'Phone',
      };
      const countryList = {
        ID: [
          contactSupport.Whatsapp,
          contactSupport.Chat,
          contactSupport.Email,
          contactSupport.Phone,
        ],
        TH: [contactSupport.Email],
        default: [contactSupport.Chat, contactSupport.Email],
      };
      const getCountry = countryList[country] || countryList.default;
      const getContactSupport = this.customerService.filter((item) => {
          if (country === 'VN' && item.name === contactSupport.Chat) {
            item.img = zalo
          }
          return getCountry.includes(item.name)
        }
      );
      this.customerService = getContactSupport;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~/assets/css/main.scss';
.content {
  background: #ffffff;
  img {
    width: 20px;
    height: 20px;
  }
}
.section-content {
  border-right: #f6f9ff 2px solid;
}
</style>
